// stores/projects.js
import { defineStore } from 'pinia';
import { fetchProjects } from '@/api';

export const useProjectStore = defineStore('projectStore', {
    state: () => ({
        projects: null,
        cachedThumbnails: {}, // Add this to store cached thumbnails
    }),
    actions: {
        async loadProjects() {
            if (this.projects) {
                return this.projects;
            }
            this.projects = await fetchProjects();
            return this.projects;
        },
        cacheThumbnails(projectId, thumbnails) { // Add this method
            this.cachedThumbnails[projectId] = thumbnails;
        },
        getCachedThumbnails(projectId) { // Add this method
            return this.cachedThumbnails[projectId] || null;
        },
    },
});