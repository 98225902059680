import { createRouter, createWebHistory } from 'vue-router'
import HelloView from "@/views/HelloView.vue";
// import Projects from "@/components/Projects.vue";
import Projects from "@/components/ProjectsView.vue";

const routes = [
  {
    // This will handle the projects list view (embedded in home)
    path: '/',
    name: 'home',
    component: HelloView
  },
  {
    path: '/exhibitions',
    name: 'exhibitions',
    component: () => import(/* webpackChunkName: "about" */ '../views/ExhibitionView.vue'),
    meta: {
      title: 'EXHIBITIONS'
    }
  },
  {
    path: '/press',
    name: 'press-links',
    component: () => import(/* webpackChunkName: "about" */ '../views/PressLinkView.vue'),
    meta: {
      title: 'PRESS'
    }
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue'),
    meta: {
      title: 'INDEX'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: {
      title: 'CONTACT'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// navigation guard to update the title
router.beforeEach((to, from, next) => {
  const defaultTitle = 'EVGENIY PAVLOV'; // Static part of the title
  const dynamicPart = to.meta.title || ''; // Dynamic part from the route's meta field
  document.title = dynamicPart ? `${dynamicPart} | ${defaultTitle}` : defaultTitle; // Update the document title
  next();
})

export default router
