<!-- ProjectsComponent.vue -->
<template>
  <div class="index">
    <div class="projects">
      <div
          v-for="project in sortedProjects"
          :key="project.id"
          :class="[ projectRowClasses(project), 'transition-opacity duration-700 ease-in-out' ]"
          :data-project-id="project.id"
          :style="[
          getProjectStyles(project),
          // Fade in only when project is in visibleProjects
          isProjectVisible(project) ? { opacity: 1 } : { opacity: 0 }
        ]"
      >
        <ProjectInfo
            :name="project.attributes.name"
            :date="project.attributes.date"
            :description="project.attributes.description"
            @click-header="handleHeaderClick(project)"
        />
        <transition name="fade" mode="out-in">
          <ImageGrid
              :project="project"
              :fullscreen-project="fullscreenProject"
              :images-are-visible="imagesAreVisible"
              :current-image-index="clickedImageIndex"
              @image-click="handleImageClick"
              @modal-click="handleModalClick"
              @navigate="handleNavigate"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick, onUnmounted, watch } from 'vue'
import ProjectInfo from './projects/ProjectInfo.vue'
import ImageGrid from './projects/ImageGrid.vue'
import { fetchProjects, sortProjectsByDate } from '@/api'
import { getImageUrl, findAdjacentProject, loadProjectThumbnails } from '@/utils/imageLoader'
import { useFullscreen } from '@/hooks/useFullscreen'
import { useModalState } from '@/hooks/useModalState'
import { useStyles } from '@/hooks/useStyles'
import { imageLoader } from '@/utils/imageLoader'
import { calculateTransformOrigin } from '@/utils/imageGrid'

export default {
  name: 'ProjectsComponent',
  components: { ProjectInfo, ImageGrid },
  setup() {
    const projects = ref([])
    const loading = ref(true)
    const loadedImages = ref(new Map())
    const projectLoadingStatus = ref(new Map())
    const { setModalState } = useModalState()
    const clickedImageRect = ref(null)
    const { fullscreenProject, clickedImageIndex, imagesAreVisible, openFullscreen, closeFullscreen } = useFullscreen()
    const { projectRowClasses, imageGridClasses, imageContainerClasses, thumbnailClasses } = useStyles(fullscreenProject, imagesAreVisible)
    const preventNavigation = ref(false)
    const sortedProjects = computed(() => sortProjectsByDate(projects.value))
    const findAdjacent = (currentProject, direction) => findAdjacentProject(sortedProjects.value, currentProject, direction)
    const visibleProjects = ref(new Set())
    const isProjectVisible = (project) => visibleProjects.value.has(project.id)
    const loadProjectsSequentially = async () => {
      if (!sortedProjects.value.length) return
      await loadProjectThumbnails(sortedProjects.value[0], imageLoader, projectLoadingStatus.value, visibleProjects)
      for (let i = 1; i < sortedProjects.value.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, 150))
        await loadProjectThumbnails(sortedProjects.value[i], imageLoader, projectLoadingStatus.value, visibleProjects)
      }
    }
    const transformOrigin = calculateTransformOrigin(clickedImageRect.value)
    const handleHeaderClick = (project) => {
      if (fullscreenProject.value && fullscreenProject.value.id === project.id) {
        closeFullscreen()
      }
    }
    const getProjectStyles = (project) => {
      if (fullscreenProject.value?.id !== project.id) return {}
      return {
        transition: 'transform 0.8s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease',
        transformOrigin: clickedImageRect.value
            ? `${clickedImageRect.value.left + clickedImageRect.value.width / 2}px ${clickedImageRect.value.top + clickedImageRect.value.height / 2}px`
            : 'center center'
      }
    }
    const handleImageClick = async (project, index, event) => {
      if (!fullscreenProject.value) {
        event.preventDefault()
        event.stopPropagation()
        const rect = event.target.getBoundingClientRect()
        clickedImageRect.value = { left: rect.left, top: rect.top, width: rect.width, height: rect.height }
        loading.value = true
        loading.value = false
        imagesAreVisible.value = false
        await nextTick()
        await toggleFullscreen(project, index)
        setTimeout(() => { clickedImageRect.value = null }, 800)
      }
    }
    const toggleFullscreen = async (project, imageIndex) => {
      if (fullscreenProject.value && fullscreenProject.value.id === project.id) {
        await closeFullscreen()
      } else {
        await openFullscreen(project, imageIndex)
        await nextTick()
        scrollToImage(project, imageIndex)
        setTimeout(() => { imagesAreVisible.value = true }, 300)
      }
    }
    const scrollToImage = async (project, imageIndex) => {
      await nextTick()
      const imageContainers = document.querySelectorAll(`.project-row-enlarged[data-project-id="${project.id}"] .image-container-enlarged`)
      if (imageContainers[imageIndex]) {
        imageContainers[imageIndex].scrollIntoView({ behavior: 'auto', inline: 'center' })
        setupObserverForImage(imageContainers[imageIndex])
      }
    }
    const setupObserverForImage = (imageElement) => {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          imagesAreVisible.value = true
          observer.disconnect()
        }
      }, { threshold: 0.5 })
      observer.observe(imageElement)
    }
    const handleModalClick = async (event) => {
      if (!fullscreenProject.value) return
      const rect = event.currentTarget.getBoundingClientRect()
      const clickX = event.clientX - rect.left
      const containerWidth = rect.width
      if (clickX < containerWidth / 2) {
        await handleLeftNavigation()
      } else {
        await handleRightNavigation()
      }
    }
    const handleNavigate = async (direction) => {
      if (!fullscreenProject.value || clickedImageIndex.value === null) return
      const project = fullscreenProject.value
      const totalImages = project.attributes.images.data.length
      let newIndex = clickedImageIndex.value + direction
      if (newIndex < 0) {
        const prevProject = findAdjacent(project, -1)
        await navigateToProject(prevProject, true)
      } else if (newIndex >= totalImages) {
        const nextProject = findAdjacent(project, 1)
        await navigateToProject(nextProject, false)
      } else {
        clickedImageIndex.value = newIndex
        scrollToImage(project, newIndex)
      }
    }
    const handleLeftNavigation = async () => {
      if (!fullscreenProject.value) return
      const totalImages = fullscreenProject.value.attributes.images.data.length
      if (clickedImageIndex.value === 0) {
        const prevProject = findAdjacent(fullscreenProject.value, -1)
        await navigateToProject(prevProject, true)
      } else {
        await navigateImage(-1)
      }
    }
    const handleRightNavigation = async () => {
      if (!fullscreenProject.value) return
      const totalImages = fullscreenProject.value.attributes.images.data.length
      if (clickedImageIndex.value === totalImages - 1) {
        const nextProject = findAdjacent(fullscreenProject.value, 1)
        await navigateToProject(nextProject, false)
      } else {
        await navigateImage(1)
      }
    }
    const navigateImage = async (direction) => {
      if (!fullscreenProject.value || clickedImageIndex.value === null) return
      const project = fullscreenProject.value
      const totalImages = project.attributes.images.data.length
      let newIndex = clickedImageIndex.value + direction
      if (newIndex < 0) {
        const prevProject = findAdjacent(project, -1)
        await navigateToProject(prevProject, true)
      } else if (newIndex >= totalImages) {
        const nextProject = findAdjacent(project, 1)
        await navigateToProject(nextProject, false)
      } else {
        clickedImageIndex.value = newIndex
        scrollToImage(project, newIndex)
      }
    }
    const navigateToProject = async (project, isLastImage) => {
      imagesAreVisible.value = false
      await new Promise(resolve => setTimeout(resolve, 300))
      fullscreenProject.value = project
      const imageIndex = isLastImage ? project.attributes.images.data.length - 1 : 0
      clickedImageIndex.value = imageIndex
      loading.value = false
      await nextTick()
      const imageGrid = document.querySelector('.image-grid-enlarged')
      if (imageGrid) {
        if (isLastImage) {
          imageGrid.scrollLeft = imageGrid.scrollWidth
        } else {
          imageGrid.scrollLeft = 0
        }
      }
      imagesAreVisible.value = true
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeFullscreen()
      } else if (event.key === 'ArrowLeft' && fullscreenProject.value) {
        navigateImage(-1)
      } else if (event.key === 'ArrowRight' && fullscreenProject.value) {
        navigateImage(1)
      }
    }
    const handleBeforeUnload = (event) => {
      if (preventNavigation.value) {
        event.preventDefault()
        event.returnValue = ''
      }
    }
    onMounted(async () => {
      try {
        const data = await fetchProjects();
        projects.value = data;

        // Load each project's first 10 thumbnails in sequence
        for (const project of projects.value) {
          await loadProjectThumbnails(
              project,
              imageLoader,           // your image loader
              projectLoadingStatus.value, // tracks loading states
              visibleProjects        // a ref(Set) for which projects to show
          );
        }
      } catch (error) {
        console.error(error);
      }

      // Optionally attach event listeners, etc.
      document.addEventListener('keydown', handleKeyDown);
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('close-modal', () => {
        if (fullscreenProject.value) {
          closeFullscreen();
        }
      });
    });
    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    })
    watch(fullscreenProject, (newValue) => {
      setModalState(!!newValue)
    })
    const isProjectLoaded = (project) => projectLoadingStatus.value.get(project.id) === true
    return {
      projects,
      sortedProjects,
      fullscreenProject,
      imagesAreVisible,
      handleImageClick,
      getImageUrl,
      handleModalClick,
      handleHeaderClick,
      thumbnailClasses,
      imageContainerClasses,
      imageGridClasses,
      projectRowClasses,
      clickedImageIndex,
      handleNavigate,
      getProjectStyles,
      isProjectLoaded,
      isProjectVisible
    }
  }
}
</script>

<style scoped lang="scss">
.projects {
  margin-top: 35vh;
}


.gallery-image {
  height: 100%;
  object-fit: cover;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
}

.image-wrapper {
  display: inline-block;
  height: 100%;
  position: relative;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1),
  height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
}

.image-wrapper.expanded .gallery-image {
  height: calc(100vh - 40px);
  object-fit: contain;
  width: auto;
}

.images-container {
  height: calc(15vh - 100px);
  min-height: 70px;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 0;
  display: inline-flex;
  gap: 20px;
  will-change: transform;
}

/* Hide native scroll in expanded mode, rely on transform. */
.images-scroll-container {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  white-space: nowrap;
  width: 100%;
}

/* In collapsed mode, allow user to scroll horizontally. */
.project-row:not(.expanded) .images-scroll-container {
  overflow-x: auto;
  scrollbar-width: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 0;
}

.images-scroll-container::-webkit-scrollbar {
  display: none;
}

.nav-button {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 50%;
}

.nav-button.left {
  left: 0;
}

.nav-button.right {
  right: 0;
}

.navigation-overlay {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.project-content {
  height: 100%;
  position: relative;
  width: 100%;
}

/* Expanded row: fill nearly entire viewport. */
.project-row.expanded {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: calc(100vh - 40px);
  margin: 0;
  z-index: 9999;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Collapsed row: ~15vh tall */
.transition-opacity {
  transition: opacity 700ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity;
}

.project-row {
  position: relative;
  height: 15vh;
  margin: 20px 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1),
  opacity 700ms cubic-bezier(0.4, 0, 0.2, 1); // Add opacity transition
}

.projects-container {
  position: relative;
  height: 100vh;
  outline: none;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}

.opacity-0 {
  opacity: 0;
}



.duration-700 {
  transition-duration: 700ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
</style>