<template>
  <div>
    <Projects :projects="projects" />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Projects from "@/components/Projects.vue";
import { useProjectStore } from '@/stores/projects';

const projectStore = useProjectStore();

const projects = ref([]);

onMounted(async () => {
  projects.value = await projectStore.loadProjects();
  console.log('Parent projects:', projects.value)
});
</script>