import axios from 'axios';
import {imageLoader} from "@/utils/imageLoader";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:1337',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export const fetchProjects = async () => {
  const cachedData = JSON.parse(localStorage.getItem('projects') || '{}');
  const { etag } = cachedData;
  const response = await apiClient.get('/api/projects?populate[images][populate]=*&pagination[pageSize]=100', {
    headers: { 'If-None-Match': etag }
  });
  if (response.status === 200) {
    localStorage.setItem('projects', JSON.stringify(response.data));
    return response.data.data;
  } else {
    return cachedData.data;
  }
};


export const getFullUrl = (path) => {
  if (!path) return '';
  const cleanPath = path.startsWith('/') ? path.slice(1) : path;
  return `${apiClient.defaults.baseURL}/${cleanPath}`;
};


export const sortProjectsByDate = (projects) => {
  const getYear = (date) => {
    if (!date) return 0;
    const years = date.split('-').map((year) => parseInt(year, 10));
    return Math.min(...years);
  };

  console.log(projects[0]);
  return [...projects].sort((a, b) => getYear(a.attributes.date) - getYear(b.attributes.date));
};

export default apiClient;

//
// const loadProjectThumbnails = async (project) => {
//   // Already loaded?
//   if (projectLoadingStatus.value.get(project.id)) return;
//
//   projectLoadingStatus.value.set(project.id, false);
//
//   const images = project.attributes.images.data;
//   try {
//     for (const image of images) {
//       const thumbnailUrl = image.attributes.formats.thumbnail.url;
//       await imageLoader.loadImage(thumbnailUrl);
//     }
//     projectLoadingStatus.value.set(project.id, true);
//     // Small delay to avoid abrupt popping, or you can remove this if you want it immediate
//     await new Promise(resolve => setTimeout(resolve, 50));
//     requestAnimationFrame(() => {
//       visibleProjects.value.add(project.id);
//     });
//   } catch (error) {
//     console.error(`Error loading thumbnails for project ${project.id}:`, error);
//     visibleProjects.value.add(project.id);
//   }
// };