<template>
  <div class="image-grid" :class="computedClasses.imageGrid" ref="imageGridRef">
    <div v-if="isFullscreen" class="navigation-container">
      <div class="navigation-left" @mousedown.stop="handleClickLeft"></div>
      <div class="navigation-right" @mousedown.stop="handleClickRight"></div>
    </div>

    <div
        v-for="(image, index) in visibleImages"
        :key="image.id"
        class="image-container"
        :class="computedClasses.imageContainer"
    >
      <img
          :src="getImageUrl(image, isFullscreen ? true : false)"
          :alt="image.attributes.name"
          class="thumbnail"
          loading="lazy"
          :class="computedClasses.thumbnail"
          @click.stop="handleImageClick(index, $event)"
          @load="onImageLoaded"
          draggable="false"
      />
    </div>

    <div v-if="isLoading">
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { getImageUrl } from '@/utils/imageLoader'

export default {
  name: 'ImageGrid',
  props: {
    project: {
      type: Object,
      required: true
    },
    fullscreenProject: {
      type: Object,
      default: null
    },
    imagesAreVisible: {
      type: Boolean,
      default: false
    },
    currentImageIndex: {
      type: Number,
      default: 0
    }
  },
  emits: ['image-click', 'modal-click', 'image-load', 'navigate'],
  setup(props, { emit }) {
    const imageGridRef = ref(null)
    const isFullscreen = computed(() => props.fullscreenProject?.id === props.project?.id)
    const isScrolling = ref(false)
    const isLoading = ref(false)
    const visibleImages = ref([])
    const batchSize = 10
    const loadedCountInBatch = ref(0)
    const currentStartIndex = ref(0)

    const totalImages = computed(() => props.project.attributes.images.data.length)

    const loadBatch = async () => {
      isLoading.value = true
      await new Promise(r => setTimeout(r, 2000));
      loadedCountInBatch.value = 0
      const batch = props.project.attributes.images.data.slice(
          currentStartIndex.value,
          currentStartIndex.value + batchSize
      )

      // Create a promise for each image so we can wait until all are loaded
      const loadPromises = batch.map((image) => {
        return new Promise((resolve) => {
          const img = new Image()
          img.src = getImageUrl(image, false)
          img.onload = () => resolve()
          img.onerror = () => resolve()
        })
      })

      // Wait until all images in this batch finish loading
      await Promise.all(loadPromises)

      // Push them into visibleImages
      visibleImages.value.push(...batch)
      isLoading.value = false
      currentStartIndex.value += batchSize

      // If there are still more images, load the next batch
      if (currentStartIndex.value < totalImages.value) {
        loadBatch()
      }
    }

    watch(isFullscreen, (newVal) => {
      if (newVal) {
        visibleImages.value = []
        currentStartIndex.value = 0
        loadBatch()
      }
    })

    const handleClickLeft = (event) => {
      if (!isScrolling.value) {
        emit('modal-click', {
          currentTarget: event.currentTarget.parentElement.parentElement,
          clientX: 0
        })
      }
    }

    const handleClickRight = (event) => {
      if (!isScrolling.value) {
        const parent = event.currentTarget.parentElement.parentElement
        emit('modal-click', {
          currentTarget: parent,
          clientX: parent.offsetWidth
        })
      }
    }

    const handleImageClick = (index, event) => {
      if (!isScrolling.value) {
        emit('image-click', props.project, index, event)
      }
    }

    const onImageLoaded = (event) => {
      emit('image-load', props.project.id, event.target.alt)
    }

    const computedClasses = computed(() => ({
      imageGrid: {
        'image-grid-enlarged': isFullscreen.value,
        'image-grid-hidden': props.fullscreenProject && !isFullscreen.value
      },
      imageContainer: {
        'image-container-enlarged': isFullscreen.value,
        'image-container-hidden': props.fullscreenProject && !isFullscreen.value
      },
      thumbnail: {
        'thumbnail-enlarged': isFullscreen.value,
        'thumbnail-hidden': props.fullscreenProject && !isFullscreen.value,
        'images-visible': isFullscreen.value && props.imagesAreVisible
      }
    }))

    onMounted(() => {
      // When NOT fullscreen, load the first 10 so we see thumbnails in the collapsed state
      loadBatch()
    })

    onUnmounted(() => {})

    return {
      isFullscreen,
      computedClasses,
      imageGridRef,
      handleImageClick,
      handleClickLeft,
      handleClickRight,
      onImageLoaded,
      visibleImages,
      isLoading,
      getImageUrl
    }
  }
}
</script>