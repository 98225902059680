<template>
  <div class="app-container">
    <Header />
    <main class="main-content">
      <div class="scroll-container">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import { onMounted, onBeforeUnmount } from "vue";
import { provideModalState } from "@/hooks/useModalState";

export default {
  components: {
    Header,
  },
  setup() {
    provideModalState();

    const handleTouchMove = (event) => {
      // If we're not in an image-grid, prevent the default behavior
      if (!event.target.closest('.image-grid')) {
        event.preventDefault();
      }
    };

    onMounted(() => {
      document.addEventListener('touchmove', handleTouchMove, {passive: false});
    });

    onBeforeUnmount(() => {
      document.removeEventListener('touchmove', handleTouchMove);
    });
  },
};
</script>

<style>
@import './assets/styles/styles.scss';

/* Prevent browser gestures globally */
:root,
body,
html {
  overscroll-behavior-x: none;
}

.scroll-container {
  position: relative;
  /* Only prevent vertical overflow behavior */
  overscroll-behavior-y: none;
  /* Allow vertical touch actions */
  touch-action: pan-y;
}

/* Image grid specific styles */
.image-grid {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(15vh - 100px);
  min-height: 70px;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 0;
  position: relative;
  z-index: 5;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* Enable all touch actions for the grid */
  touch-action: pan-x pan-y pinch-zoom;
}

.image-grid::-webkit-scrollbar {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>