// utils/fullscreenUtils.js
export const calculateTransformOrigin = (imageRect) => {
    if (!imageRect) return 'center center';
    return `${imageRect.left + imageRect.width / 2}px ${imageRect.top + imageRect.height / 2}px`;
};

/**
 * Handles navigation within a project's images or between adjacent projects.
 */
export const handleNavigation = async (
    direction,
    fullscreenProject,
    clickedImageIndex,
    sortedProjects,
    findAdjacentProject,
    scrollToImage,
    navigateToProject
) => {
    if (!fullscreenProject.value || clickedImageIndex.value === null) return;

    const project = fullscreenProject.value;
    const totalImages = project.attributes.images.data.length;
    let newIndex = clickedImageIndex.value + direction;

    if (newIndex < 0) {
        const prevProject = findAdjacentProject(sortedProjects, project, -1);
        await navigateToProject(prevProject, true);
    } else if (newIndex >= totalImages) {
        const nextProject = findAdjacentProject(sortedProjects, project, 1);
        await navigateToProject(nextProject, false);
    } else {
        clickedImageIndex.value = newIndex;
        await scrollToImage(project, newIndex);
    }
};

/**
 * Navigates to the previous project or moves left within the current project's images.
 */
export const handleLeftNavigation = async (
    fullscreenProject,
    clickedImageIndex,
    findAdjacentProject,
    navigateImage,
    navigateToProject
) => {
    if (!fullscreenProject.value) return;

    const totalImages = fullscreenProject.value.attributes.images.data.length;
    const isAtStart = clickedImageIndex.value === 0;

    if (isAtStart) {
        const prevProject = findAdjacentProject(fullscreenProject.value, -1);
        await navigateToProject(prevProject, true);
    } else {
        await navigateImage(-1);
    }
};

/**
 * Navigates to the next project or moves right within the current project's images.
 */
export const handleRightNavigation = async (
    fullscreenProject,
    clickedImageIndex,
    findAdjacentProject,
    navigateImage,
    navigateToProject
) => {
    if (!fullscreenProject.value) return;

    const totalImages = fullscreenProject.value.attributes.images.data.length;
    const isAtEnd = clickedImageIndex.value === totalImages - 1;

    if (isAtEnd) {
        const nextProject = findAdjacentProject(fullscreenProject.value, 1);
        await navigateToProject(nextProject, false);
    } else {
        await navigateImage(1);
    }
};